#share-link .share-button{
  background-color: #00A3FF;
  border-radius: 5px;
  color:#FFFFFF;
  border:none;
  padding: 0.5rem 1rem;
}

#share-link .share-button:focus{
  outline: none;
}

.modal-content .share-button{
  background-color: #00A3FF;
  border-radius: 5px;
  color:#FFFFFF;
  border:none;
  padding: 0.5rem 1rem;
}

.modal-content .share-button:focus{
  outline: none;
}

.modal-footer{
  justify-content: flex-start;
}

.toast.show{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: 1rem;
}