
#naya-navbar
{
}

#naya-navbar .navbar-brand
{
    font-family: bigmoore, serif;
    font-size: 2rem;
    font-weight: 700;
}

#naya-navbar .nav-link{
  font-family: sofia-pro;
  font-weight:700;
}

#naya-navbar .dropdown-item{
  font-family: sofia-pro;
  font-weight: 400;
}


.dropdown-menu {
    max-height: 60vh;
    overflow-y: auto;
    right: 0;
    left: auto;
}

/* width */
.dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: #CCCCCC; 
  }
  
  /* Handle on hover */
  .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #CCCCCC; 
  }

  /* .prompts .modal-dialog{
    position: absolute; 
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: fit-content;
  } */