.shared
{
    padding: 1rem;
    padding-bottom: 2rem;
    padding-top: 0rem;
    background-color: rgb(245, 245, 245);
    margin-bottom: 2rem;
}

.shared .content
{
    text-align:center;
    font-size: 1.2rem;
}

.title-block p
{
    margin-bottom: 0px;
    font-size: 0.85rem;
}